import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, setNestedObjectValues } from 'formik'
import {
  FocusView,
  FocusHeader,
  HeadingGroup,
  Title,
  FocusActions,
  FocusClose,
  FocusBody,
  Panel
} from '@toasttab/buffet-pui-config-templates'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { Owner, ProspectLoanFormValues } from '../types'
import { OwnerRoleSelect } from './OwnerRoleSelect'
import { OwnershipFields } from './OwnershipFields'
import FinancingApi from '../../../src/api/Financing'
import {
  ValidationLoadingModal,
  InvalidInfoModal,
  ExceededMaxAttemptsModal
} from '../shared'
import { useParams } from 'react-router-dom'
import { useLimitValidationAttempts } from '../useLimitValidationAttempts'

interface Props {
  owners: Owner[]
  onRequestClose(): void
  removeOwner(index: number): void
}

export const AddPersonModal = ({
  owners,
  onRequestClose,
  removeOwner
}: Props): ReactElement => {
  const { validateForm, setTouched } =
    useFormikContext<ProspectLoanFormValues>()
  const { t } = useTranslation()
  const { dealNumber } = useParams<{ dealNumber: string }>()

  const [isInvalidInfoModalOpen, setIsInvalidInfoModalOpen] = useState(false)
  const [isExceededMaxAttemptsModalOpen, setIsExceededMaxAttemptsModalOpen] =
    useState(false)
  const [isValidationLoading, setIsValidationLoading] = useState(false)

  const currentIndex = owners.length - 1

  const handleClose = () => {
    // Remove the owner currently being edited
    removeOwner(currentIndex)
    onRequestClose()
  }

  const { canValidateOwnership, incrementOwnershipValidationAttempts } =
    useLimitValidationAttempts()

  const handleSubmit = async () => {
    const validationErrors = await validateForm()

    if (Object.keys(validationErrors).length > 0) {
      await setTouched(setNestedObjectValues(validationErrors, true))
    } else {
      if (canValidateOwnership()) {
        setIsValidationLoading(true)

        const owner = owners[currentIndex]

        FinancingApi.validateOwnershipInfo(dealNumber, {
          firstName: owner.firstName,
          lastName: owner.lastName,
          address: { ...owner.address, country: 'US' },
          dob: owner.dob,
          ssn: owner.ssn,
          phoneNumber: owner.phoneNumber,
          ownershipStake: owner.ownershipStake,
          email: owner.email
        })
          .then(() => onRequestClose())
          .catch(() => {
            incrementOwnershipValidationAttempts()
            setIsInvalidInfoModalOpen(true)
          })
          .finally(() => setIsValidationLoading(false))
      } else {
        setIsExceededMaxAttemptsModalOpen(true)
      }
    }
  }

  return (
    <>
      <FocusView testId='add-person-modal'>
        <FocusHeader>
          <HeadingGroup
            subTitle={t('prospectLoans.ownership.addOwnerModal.businessSetup')}
          >
            <Title>{t('prospectLoans.ownership.addOwnerModal.title')}</Title>
          </HeadingGroup>
          <FocusActions>
            <Button variant='secondary' onClick={handleClose} className='mr-4'>
              {t('prospectLoans.ownership.addOwnerModal.cancel')}
            </Button>
            <Button
              testId='save-owner-button'
              disabled={isValidationLoading}
              onClick={handleSubmit}
              iconLeft={<AddIcon accessibility='decorative' />}
            >
              {t('prospectLoans.ownership.addOwnerModal.add')}
            </Button>
          </FocusActions>
          <FocusClose as='button' onClick={handleClose} />
        </FocusHeader>
        <FocusBody>
          <Panel className='mb-8'>
            <p className='font-semibold text-default'>
              {t(
                'prospectLoans.ownership.addOwnerModal.ownerRoleSection.title'
              )}
            </p>
            <p className='text-secondary mb-6'>
              {t(
                'prospectLoans.ownership.addOwnerModal.ownerRoleSection.description'
              )}
            </p>
            <OwnerRoleSelect owners={owners} />
          </Panel>
          <Panel>
            <p className='font-semibold text-default'>
              {t('prospectLoans.ownership.addOwnerModal.formSection.title')}
            </p>
            <p className='text-secondary mb-6'>
              {t(
                'prospectLoans.ownership.addOwnerModal.formSection.description'
              )}
            </p>
            <OwnershipFields owners={owners} />
          </Panel>
        </FocusBody>
      </FocusView>
      <InvalidInfoModal
        isOpen={isInvalidInfoModalOpen}
        onRequestClose={() => setIsInvalidInfoModalOpen(false)}
      />
      <ValidationLoadingModal isOpen={isValidationLoading} />
      <ExceededMaxAttemptsModal
        isOpen={isExceededMaxAttemptsModalOpen}
        onRequestClose={() => setIsExceededMaxAttemptsModalOpen(false)}
      />
    </>
  )
}
