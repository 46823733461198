import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import {
  PageBody,
  PageMain,
  ContentFooter,
  ContinueButton,
  BackButton
} from '@toasttab/buffet-pui-wizard-templates'
import { SelectField } from '@toasttab/buffet-pui-forms'
import { FlowProps } from '@local/use-flow-navigation'
import { RestaurantLegalInfoFields } from '@local/restaurant-legal-info-fields'
import {
  ProspectLoanStepProps,
  OWNERSHIP_TYPE,
  ProspectLoanFormValues
} from './types'
import {
  PageTitle,
  ValidationLoadingModal,
  InvalidInfoModal,
  ExceededMaxAttemptsModal
} from './shared'
import FinancingApi from '../../src/api/Financing'
import { useLimitValidationAttempts } from './useLimitValidationAttempts'

export const BusinessInfo = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { values } = useFormikContext<ProspectLoanFormValues>()
  const { dealNumber } = useParams<{ dealNumber: string }>()

  const [isInvalidBusinessInfoModalOpen, setIsInvalidBusinessInfoModalOpen] =
    useState(false)
  const [isExceededMaxAttemptsModalOpen, setIsExceededMaxAttemptsModalOpen] =
    useState(false)
  const [isValidationLoading, setIsValidationLoading] = useState(false)

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const { canValidateBusinessInfo, incrementBusinessInfoValidationAttempts } =
    useLimitValidationAttempts()

  const handleSubmit = () => {
    if (canValidateBusinessInfo()) {
      setIsValidationLoading(true)
      FinancingApi.validateBusinessInfo(dealNumber, {
        name: values.merchant.name,
        tin: values.merchant.tin
      })
        .then(() => goToNextStep())
        .catch(() => {
          setIsInvalidBusinessInfoModalOpen(true)
          incrementBusinessInfoValidationAttempts()
        })
        .finally(() => setIsValidationLoading(false))
    } else {
      setIsExceededMaxAttemptsModalOpen(true)
    }
  }

  return (
    <>
      <PageBody testId='prospect-loans-business-info-page'>
        <PageMain>
          <p className='uppercase font-semibold type-overline text-secondary mb-6'>
            {t('prospectLoans.businessInfo.stepTwo')}
          </p>
          <PageTitle
            title={t('prospectLoans.businessInfo.title')}
            description={t('prospectLoans.businessInfo.businessDescription')}
          />
          <p className='type-large text-default font-medium mt-6 mb-4'>
            {t('common.business.fields.title')}
          </p>
          <RestaurantLegalInfoFields showRequiredLabel hidePhoneField />
          <div className='grid grid-cols-2 gap-y-4 gap-x-8 mt-2'>
            <div className='col-span-2 md:col-span-1'>
              <label
                className='flex w-full mb-1 items-center type-default font-semibold text-default'
                htmlFor='ownership-type-select-field'
              >
                <span className='mr-2'>
                  <span className='text-error'>*&nbsp;</span>
                  {t('prospectLoans.businessInfo.fields.ownershipType.label')}
                </span>
              </label>
              <SelectField
                id='ownership-type-select-field'
                placement='top'
                className='fs-mask'
                testId='ownershipType'
                options={Object.values(OWNERSHIP_TYPE).map((type) => ({
                  value: type,
                  label: t(
                    `prospectLoans.businessInfo.fields.ownershipType.options.${type}`
                  )
                }))}
                name='ownershipType'
                aria-label='ownershipType'
              />
            </div>
          </div>
          <ContentFooter className='mt-6 py-6 bg-white'>
            <div className='flex justify-end w-full'>
              <BackButton onClick={goToPreviousStep} />
              <ContinueButton
                onClick={handleSubmit}
                disabled={isValidationLoading || !isValid}
                className='mr-6'
                testId='business-info'
              >
                {isValidationLoading
                  ? t('prospectLoans.shared.loading')
                  : t('prospectLoans.shared.continue')}
              </ContinueButton>
            </div>
          </ContentFooter>
        </PageMain>
      </PageBody>
      <InvalidInfoModal
        isBusinessInfo
        isOpen={isInvalidBusinessInfoModalOpen}
        onRequestClose={() => setIsInvalidBusinessInfoModalOpen(false)}
      />
      <ValidationLoadingModal isBusinessInfo isOpen={isValidationLoading} />
      <ExceededMaxAttemptsModal
        isOpen={isExceededMaxAttemptsModalOpen}
        onRequestClose={() => setIsExceededMaxAttemptsModalOpen(false)}
      />
    </>
  )
}
