import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onRequestClose(): void
}

export const ExceededMaxAttemptsModal = ({ isOpen, onRequestClose }: Props) => {
  const { t } = useTranslation()
  return (
    <Modal
      testId='exceeded-max-attempts-modal'
      // @ts-ignore
      appElement={document.body}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalHeader>
        <div className='pb-4'>{t('prospectLoans.shared.sorry')}</div>
      </ModalHeader>
      <ModalBody>
        <div>{t('prospectLoans.shared.exceededMaxAttempts')}</div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onRequestClose} data-testid='close'>
          {t('prospectLoans.shared.gotIt')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
