import { EasyPayApplicationForm } from './types'

export const scrollToTop = () => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }
  window.scrollTo(0, 0)
}

export const formatRequestBody = (values: EasyPayApplicationForm) => {
  return {
    applicationType: 'lease',
    ...values,
    merchant: {
      ...values.merchant,
      address: {
        ...values.merchant.address,
        country: 'US'
      }
    },
    restaurant: {
      ...values.restaurant,
      address: {
        ...values.restaurant.address,
        country: 'US'
      }
    },
    owner: {
      ...values.owner,
      address: {
        ...values.owner.address,
        country: 'US'
      },
      dob: null,
      ssn: null,
      ownershipStake: null
    }
  }
}
