import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { useFlowNavigation } from '@local/use-flow-navigation'
import { Page, PageContainer } from '@toasttab/buffet-pui-wizard-templates'
import ToastLogo from '../../src/components/ToastLogo'
import FinancingApi from '../../src/api/Financing'
import { EasyPayWelcome } from './Welcome'
import { BusinessInfo } from './BusinessInfo'
import { RestaurantInfo } from './RestaurantInfo'
import { ReviewTerms } from './ReviewTerms'
import { Submitting } from './Submitting'
import { EasyPayStepProps, EasyPaySteps, EasyPayApplicationForm } from './types'
import { useValidationSchema } from './validation'
import { formatRequestBody } from './helpers'
import { ThankYou } from './ThankYou'
import { Error } from './Error'

export const EasyPay = (): ReactElement => {
  const { dealNumber } = useParams<{ dealNumber: string }>()
  const { StepComponent, currentStepId, goToStep } =
    useFlowNavigation<EasyPayStepProps>({
      steps: [
        {
          id: EasyPaySteps.WELCOME,
          component: EasyPayWelcome
        },
        {
          id: EasyPaySteps.RESTAURANT_INFO,
          component: RestaurantInfo
        },
        {
          id: EasyPaySteps.BUSINESS_INFO,
          component: BusinessInfo
        },
        {
          id: EasyPaySteps.REVIEW_TERMS,
          component: ReviewTerms
        },
        {
          id: EasyPaySteps.SUBMITTING,
          component: Submitting
        },
        {
          id: EasyPaySteps.THANK_YOU,
          component: ThankYou
        },
        {
          id: EasyPaySteps.ERROR,
          component: Error
        }
      ]
    })

  const validationSchema = useValidationSchema(currentStepId as EasyPaySteps)

  return (
    <Formik
      initialValues={
        {
          owner: {
            disclosureCheckbox: false
          }
        } as EasyPayApplicationForm
      }
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        goToStep(EasyPaySteps.SUBMITTING)
        try {
          await FinancingApi.submitApplication(
            dealNumber,
            formatRequestBody(values)
          )
          goToStep(EasyPaySteps.THANK_YOU)
        } catch (e) {
          goToStep(EasyPaySteps.ERROR)
        }
      }}
    >
      {({ isValid, validateForm }) => (
        <Page>
          <div className='absolute pt-3 pl-4 md:pt-8 md:pl-12 z-50'>
            <ToastLogo />
          </div>
          <PageContainer>
            <StepComponent isValid={isValid} validateForm={validateForm} />
          </PageContainer>
        </Page>
      )}
    </Formik>
  )
}
